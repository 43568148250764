$version: 18 !default;

@import './partners/vars';

@import './vars';

@import 'camelot';

@include font('Inter', 'inter/', 'Inter-Light', 300);

@include font('Inter', 'inter/', 'Inter-Regular', 400);

@include font('Inter', 'inter/', 'Inter-SemiBold', 600);
